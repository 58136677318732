function boom() {
	const wrapper = document.querySelectorAll('.bowe-stories');

	if (!Swiper) {
		console.warn('Post Slideshow has been disabled, SwiperJS not found');
	}

	wrapper.forEach((block) => {
		// Check if Swiper has already been initialized on this block
		if (block.classList.contains('swiper-initialized')) {
			return; // Exit the loop iteration if Swiper is already initialized
		}

		//let isFirstSlideInView = false; // To track if the first slide is in view
		const options = {
			autoplay: false,
			slidesPerView: 1,
			loopedSlides: 2,
			loop: true,
			arrows: true,
			preventClicks: false,
			preventClicksPropagation: true,
			simulateTouch: false,
			spaceBetween: 10,
			navigation: {
				nextEl: block.querySelector('.swiper-button-next'),
				prevEl: block.querySelector('.swiper-button-prev'),
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			breakpoints: {
				777: {
					spaceBetween: 17,
					//draggable: false,
				},
			},
			on: {
				slideChange(_swiper) {
					_swiper.el
						?.querySelectorAll('video')
						.forEach(function (video) {
							video.pause();
						});

					let activeSlide = _swiper.slides[_swiper.activeIndex];
					const video = activeSlide.querySelector('video');

					if (video) {
						// Check if the video has a data-src attribute
						const videoSource =
							video.querySelector('source[data-src]');
						if (videoSource) {
							// If data-src attribute exists, set its value as the src attribute and remove data-src
							videoSource.src =
								videoSource.getAttribute('data-src');
							videoSource.removeAttribute('data-src');

							// Must load the video after changing the source
							video.load();
						}

						// Delay playing to ensure video is ready, especially after src change
						setTimeout(() => {
							video.play();
						}, 200);
					}
				},
			},
		};

		const swiperInstance = new Swiper(block, options);

		// Get the first slide and its video
		const firstSlide = block.querySelector(
			'.swiper-slide.swiper-slide-active video'
		);

		firstSlide.autoplay = true;

		//const video = firstSlide.querySelector("video");

		/*if (!video) return; // Exit if the first slide doesn't have a video

    // Intersection Observer to detect when the Swiper container is in the viewport
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust this value based on how much of the Swiper container should be visible before triggering
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isFirstSlideInView = true;
          if (swiperInstance.activeIndex === 2) {
            video.play();
          }
        } else {
          isFirstSlideInView = false;
          video.pause();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(block); // Observe the Swiper container
	*/
	});
}

// Call the boom function to start observing videos

document.addEventListener('DOMContentLoaded', boom);
